.instagram-wrapper {
  display: flex;
  flex-direction: column;
  /* margin: 20px; */
  padding: 40px;
}

.video-ig {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 10px;
  width: 300px;
  height: 300px;
  text-align: center;

  border-radius: 30px;
}

.instagram-title {
  font-size: 12px;
  font-weight: 500;
  padding-bottom: 10px;
}

.instagram-icons-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.btn-style {
  display: flex;
  justify-content: center;
  margin: 40px 0;
}

@media (min-width: 1090px) and (max-width: 1129px) {
  .video-ig {
    margin-right: 45px;
  }
}

@media (min-width: 778px) and (max-width: 1130px) {
  .video-ig {
    text-align: start;
    width: 300px;
  }
}

@media (min-width: 1130px) and (max-width: 1269px) {
  .video-ig {
    text-align: start;
    width: 210px;
    height: 210px;
  }
}

@media (min-width: 1270px) and (max-width: 1499px) {
  .video-ig {
    text-align: start;
    width: 250px;
    height: 250px;
  }
}

@media (min-width: 778px) {
  .instagram-wrapper {
    padding: 55px 6%;
    /* margin: 25px 6%; */
  }

  .instagram-title {
    font-size: 25px;
  }

  .instagram-icons-wrapper {
    justify-content: space-around;
    flex-direction: row;
  }

  .btn-style {
    display: flex;
    padding-left: 10px;
    padding-top: 10px;
    justify-content: flex-start;
    margin: 0 0 0 10px;
  }
}
